<template>
    <div class="tag" :class="{yellow: isYellow}">
        <slot></slot>
    </div>
</template>
<script>
    export default {
        name: "Tag",
        props: {
            isYellow: {
                type: Boolean,
                default: false
            }
        }
    };
</script>
<style lang="scss">
    .yellow {
        background-color: var(--yellow_5) !important;
    }
    .tag {
        border-radius: 5px;
        display: inline-flex;
        box-sizing: border-box;
        padding: 8px;
        background-color: var(--main_5);
        color: var(--white);
        font-size: 13px;
        line-height: 1.5;
    }
</style>