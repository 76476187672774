<template>
    <a class="link_card" :href="link" :target="target">
        <div class="link_card_img">
            <img :src="img">
        </div>
        <div class="link_card_text">
            <div class="link_card_title">
                <slot></slot>
            </div>
            <div class="link_card_date">{{ date }}</div>
        </div>
    </a>
</template>
<script>
    export default {
        name: "LinkCard",
        props: {
            img: String,
            link: String,
            date: String,
            target: {
                default: "_blank",
                type: String,
            }
        }
    };
</script>
<style lang="scss">
    .link_card {
        text-decoration: none;
        color: var(--grey_8);
        transition: .3s box-shadow;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 20px;
        background-color: var(--white);
        display: flex;
        align-items: center;
        &:hover {
            box-shadow: 0 6px 24px rgba(22, 31, 80, 0.16);
        }
    }
    .link_card_img {
        flex-shrink: 0;
        width: 80px;
        font-size: 0;
        img {
            border-radius: 4px;
            width: 100%;
        }
    }
    .link_card_text {
        margin-left: 20px;
        font-size: 14px;
    }
    .link_card_date {
        margin-top: 5px;
        color: var(--grey_3);
    }
    .mirror {
        .link_card:hover {
            box-shadow: 0 6px 22px rgba(8, 12, 34, 0.8);
        }
    }
</style>