<template>
  <a class="card" :style="{ width: width }" :href="link" :target="target">
    <slot></slot>
    <h1 class="card_title">{{ title }}</h1>
    <div class="card_img">
      <img :src="img" />
    </div>
    <div class="card_description">{{ description }}</div>
    <div class="card_date">{{ date }}</div>
  </a>
</template>
<script>
export default {
  name: "Card",
  props: {
    tag: String,
    title: String,
    img: String,
    width: String,
    description: String,
    date: String,
    link: String,
    target: {
      default: "_blank",
      type: String,
    },
  },
};
</script>
<style lang="scss">
.card {
  transition: 0.3s box-shadow;
  position: relative;
  text-decoration: none;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
  padding-bottom: 60px;
  width: 100%;
  background-color: var(--white);
  &:hover {
    box-shadow: 0 6px 24px rgba(22, 31, 80, 0.19);
  }
  .tag + .tag {
    margin-left: 12px;
  }
}
.card_title {
  font-weight: bold;
  font-size: 24px;
  color: var(--grey_9);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card_img {
  width: 100%;
  img {
    border-radius: 4px;
    width: 100%;
    display: block;
  }
}
.card_description {
  color: var(--grey_8);
  margin-top: 20px;
  font-size: 14px;
}
.card_date {
  margin-top: 20px;
  font-size: 13px;
  color: var(--grey_5);
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.mirror {
    .card:hover {
        box-shadow: 0 6px 24px rgb(9, 13, 34, 0.9);
    }
}
</style>