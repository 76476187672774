<template>
    <div class="header">
        <div class="header_container">
            <div class="header_left">
                <h1 class="header_title">Hi, I’m Vic!</h1>
                <div class="header_subtitle">
                    I’m a designer and a front-end developer, I enjoy building something easy-to-use and funny, it’s like reconstructing the common grounds in life, and let more people feel the joys they brought.
                </div>
            </div>
            <div class="header_right">
                <a class="header_icon" href="https://twitter.com/justcaliturner" target="_blank">
                    <TwitterIcon size="1x"></TwitterIcon>
                </a>
                <a class="header_icon" href="https://github.com/justcaliturner" target="_blank">
                    <GithubIcon size="1x"></GithubIcon>
                </a>
                <a class="header_icon" href="mailto: justcaliturner@gmail.com">
                    <MailIcon size="1x"></MailIcon>
                </a>
                <div class="header_avatar">
                    <img src="../assets/avatar.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        TwitterIcon,
        GithubIcon,
        MailIcon,
    } from "@zhuowenli/vue-feather-icons";
    export default {
        name: "Header",
        components: {
            TwitterIcon,
            GithubIcon,
            MailIcon,
        }
    };
</script>
<style lang="scss">
    .header {
        box-sizing: border-box;
        width: 100%;
        background-color: var(--white);
        padding: 36px 0;
        
    }
    .header_container {
        box-sizing: border-box;
        padding: 0 20px;
        max-width: 1400px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header_title {
        margin: 0;
        margin-bottom: 4px;
        font-weight: bold;
        font-size: 40px;
        color: var(--grey_9);
    }
    .header_left {
        margin-right: 20px;
    }
    .header_right {
        display: flex;
        align-items: center;
    }
    .header_icon {
        transition: .2s background-color;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        box-sizing: border-box;
        background-color: var(--grey_3);
        color: var(--white);
        margin-right: 14px;
        &:hover {
            background-color: var(--main_5);
        }
        & + div {
            margin-left: 12px;
        }
    }
    .header_avatar {
        width: 80px;
        border-radius: 50%;
        overflow: hidden;
        font-size: 0;
        img {
            width: 100%;
        }
    }
    .header_subtitle {
        font-size: 14px;
        max-width: 510px;
        color: var(--grey_9);
    }
    @media screen and (max-width: 630px) {
        .header_container {
            display: block;
        }
        .header_right {
            margin-top: 20px;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
        .header_icon + div {
            margin-left: 0;
            margin-right: 12px;
        }
    }
</style>