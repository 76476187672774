<template>
    <div>
        <div class="float_button" @click="switchTheme">
            <SunIcon size="1.2x"></SunIcon>
            <MoonIcon size="1.2x"></MoonIcon>
        </div>
        <Header></Header>
        <div class="container">
            <div class="container_left">
                <Card link="https://www.seeasas.com" title="Seeasas" description="A website for CSS learning with friendly interaction." date="2021 / 12 / 10" :img="require(`@/assets/seeasas.jpg`)">
                    <Tag :isYellow="true">BETA</Tag>
                </Card>
                <Card link="https://lisa.vicuxd.com/landing.html" title="Lisa Color" description="Lisa Color is a tool that makes building color system easier and faster." date="2021 / 1 / 10" :img="require(`@/assets/lisa_color.jpg`)">
                    <Tag>TOOL</Tag>
                </Card>
                <Card link="https://sce.vicuxd.com/" title="Simple Code Editor" description="A simple code editor for Vue.js, which is light, easy-to-use, and supported customizing themes." date="2021 / 8 / 19" :img="require(`@/assets/sce.jpg`)">
                    <Tag>CODING</Tag>
                </Card>
                <Card link="https://vue-resizer.vicuxd.com/" title="Vue Resizer" description="A series of the resizer components for Vue.js." date="2022 / 3 / 26" :img="require(`@/assets/resizer.jpg`)">
                    <Tag>PACKAGE</Tag>
                </Card>
                <Card link="https://3d-personal-website.vicuxd.com/" title="3D Personal Website" description="A classic website developed with jQuery and CSS3 animation, and it's my first personal website." date="2017 / 11 / 16" :img="require(`@/assets/3d_website.jpg`)">
                    <Tag>CMS</Tag>
                </Card>
                <Card link="https://chaier.art/" title="My Personal Studio" description="The website of my personal studio, which introduces our business and thoughts." date="2020 / 10 / 10" :img="require(`@/assets/chaier.jpg`)">
                    <Tag>STUDIO</Tag>
                </Card>
            </div>
            <div class="container_right">
                <LinkCard link="https://prediction.vicuxd.com/" :img="require(`@/assets/chinese_astrology.png`)" date="2021/10/27">
                    Four Pillars of Destiny
                </LinkCard>
                <LinkCard link="https://cui-pc.vicuxd.com/" :img="require(`@/assets/coolpad_desptop.jpg`)" date="2016/12/20">
                    Coolpad UI Kits for Desktop
                </LinkCard>
                <LinkCard link="https://cui-mobile.vicuxd.com/" :img="require(`@/assets/coolpad_mobile.jpg`)" date="2017/9/15">
                    Coolpad UI Kits for Mobile
                </LinkCard>
                <LinkCard link="https://mg-for-lottie.vicuxd.com/" :img="require(`@/assets/if.jpg`)" date="2017/10/16">
                    Motion Graphic for Lottie
                </LinkCard>
                <div class="articles">
                    <h1 class="articles_title">WRITING</h1>
                    <ul class="articles_list">
                        <li>
                            <a href="https://blog.vicuxd.com" target="_blank">Vic's Blog</a>
                        </li>
                        <li>
                            <a href="https://gratis-soursop-47b.notion.site/Flex-bd6a62c9ea7d4db2af7df57d42ff5ac5" target="_blank">Flex Layout</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Tag from "./components/Tag.vue";
    import Card from "./components/Card.vue";
    import Header from "./components/Header.vue";
    import LinkCard from "./components/LinkCard.vue";
    import {
        SunIcon,
        MoonIcon
    } from "@zhuowenli/vue-feather-icons";
    export default {
        components: {
            SunIcon,
            MoonIcon,
            Card,
            Header,
            LinkCard,
            Tag
        },
        mounted() {
            this.$nextTick(function () {
                if (localStorage.getItem('lisa_theme')) {
                    localStorage.getItem('lisa_theme') == 'light'
                    ? (document.body.className = '')
                    : (document.body.className = 'mirror');
                } else {
                    document.body.className = 'mirror';
                }
            });
        },
        methods: {
            switchTheme() {
                if (document.body.className === '') {
                    document.body.className = 'mirror';
                    localStorage.setItem('lisa_theme', 'mirror');
                } else {
                    document.body.className = '';
                    localStorage.setItem('lisa_theme', 'light');
                }
            },
        }
    }
</script>

<style lang="scss">
    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-ExtraBold.woff2') format('woff2'),
            url('./fonts/Poppins-ExtraBold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-Regular.woff2') format('woff2'),
            url('./fonts/Poppins-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    @import "./themes/lisa.css";
    @import "./themes/lisa_mirror.css";
    body {
        font-family: 'Poppins', sans-serif;
        background-color: var(--grey_0);
        margin: 0;
    }
    .mirror {
        .feather-sun {
            display: block;
        }
        .feather-moon {
            display: none;
        }  
    }
    .feather-sun {
        display: none;
    }
    .float_button {
        transition: .2s opacity;
        cursor: pointer;
        position: fixed;
        z-index: 9;
        opacity: .9;
        right: 40px;
        bottom: 40px;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--grey_8);
        color: var(--white);
        &:hover {
            opacity: 1;
        }
    }
    .container {
        box-sizing: border-box;
        padding: 0 20px;
        padding-bottom: 120px;
        max-width: 1400px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-gap: 20px;
    }
    .container_left {
        margin-top: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
    .container_right {
        margin-top: 40px;
    }
    .link_card + .link_card,
    .link_card + div {
        margin-top: 20px;
    }
    .articles {
        box-sizing: border-box;
        padding: 20px;
        border-radius: 8px;
        background-color: var(--white);
    }
    .articles_title {
        font-size: 26px;
        margin: 0;
        font-weight: bold;
        color: var(--grey_2);
    }
    .articles_list {
        margin: 0;
        margin-top: 15px;
        padding: 0;
        list-style: none;
        li + li {
            margin-top: 16px;
        }
        a {
            display: block;
            transition: .2s color, .2s box-shadow, .2s background-color;
            color: var(--grey_8);
            text-decoration: none;
            &:hover {
                box-shadow: 0 0 0 6px var(--grey_0);
                background-color: var(--grey_0);
                color: var(--main_5);
            }
        }
    }
    @media screen and (max-width: 900px) {
        .container {
            display: block;
        }
    }
    @media screen and (max-width: 630px) {
        .container_left {
            grid-template-columns: 1fr;
        }
        .float_button {
            bottom: 20px;
            right: 20px;
        }
    }
</style>